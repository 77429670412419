@import "../../../../config/constants";

.client-card {
    background-color: white;
    border: 1px solid $light-blue-border !important;
    margin-bottom: 20px;
    padding: 15px 0;
    border-radius: 2px;
    &-title {
        margin-top: 10px;
        font-size: 17px;
        font-weight: 500;
        height: 50px;
        text-align: center;
        text-transform: capitalize;
    }
}

.btn-outlined {
    color: $green !important;
    background-color: transparent !important;
    border: 1px solid $green !important;
  }

.timespan-header {
    text-transform: uppercase;
    text-align: right;
    padding-right: 2em;
    color: $dark-blue-text;

    a:link,
    a:visited,
    a:hover,
    a:active  {
        text-decoration: none;
        color: $dark-blue-text;
    }

    .header-link {
        text-align: center;
        font-size: 10px;
        letter-spacing: 0.1em;
        line-height: 1.5em;
        height: 8em;
        padding-left: 2em;
        padding-right: 2em;
        div {
            color: $fb-dark-blue-opacity;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
            border-bottom: solid 2px $fb-link-blue;
            div {
                color: $fb-dark-blue-opacity;
            }
        }
    }

    .header-detail-link {
        text-align: center;
        font-size: 10px;
        letter-spacing: 0.1em;
        line-height: 1.5em;
        height: 8em;
        padding-left: 3em;
        padding-right: 3em;
        color: $fb-dark-blue-opacity !important;
        div {
            color: $fb-dark-blue-opacity;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
            border-bottom: solid 2px $fb-link-blue;
            div {
                color: $dark-blue-text;
            }
        }
    }
}

