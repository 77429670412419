@import "../../config/constants.scss";

.modal-open {
  padding: 0 !important;
}

.modal-header {
  align-items: center;
  justify-content: center;
  border: none;
  flex-direction: column-reverse;
  .modal-title {
    width: 100%;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }
  .close {
    color: $light-blue-text;
    display: inline;
  }
  button:focus {
    outline: none;
  }
}

.modal-footer {
  border-top: 1px solid $light-blue-border;
}

.modal.fade-down {
  .modal-header {
    background-color: $light-blue;
    border-bottom: 1px solid $light-blue-border;
    .close {
      padding-bottom: 0;
    }
  }
  .modal-dialog {
    transform: translate(0, 50px);
    margin: 0;
    position: absolute;
    max-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    .modal-body {
      overflow-y: auto;
    }
    .modal-content {
      // max-height: 80vh;
      border: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal {
  .item {
    border-top: 1px solid $light-blue-border;
    padding: 15px;
    background-color: white;
  }
  .item:last-child {
    border-bottom: 1px solid $light-blue-border;
  }
}

// .modal.modal-inventoryView {
//   .modal-dialog {
//     text-align: center;
//     color: $dark-blue-text;
//     max-width: 900px;

//     .contentBody {
//       background: #f5f9fc;
//       height: 380px;
//       img {
//         width: 100%;
//         height: 380px;
//       }
//       .closeButton {
//         position: absolute;
//         right: 12px;
//         top: 4px;
//         background: transparent;
//         border: transparent;
//         font-size: 32px;
//         color: #b8c8d2;
//       }
//     }

//     .contentText {
//       height: 157px;
//       span {
//         font-size: 26px;
//         line-height: 62px;
//         font-weight: 300;
//       }
//       p {
//         font-size: 16px !important;
//       }
//     }
//     .buttonBlock {
//       display: block !important;
//     }
//     .contentButtons {
//       display: flex;
//       justify-content: space-between;
//       padding: 0px 130px;
//       margin-bottom: 10px;
//       button {
//         height: 33px;
//         width: 135px;
//         border: none;

//         &.colorWhite {
//           background-color: #f0f0f0 !important;
//           color: #143357 !important;
//         }
//       }
//     }
//   }
// }

