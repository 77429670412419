.svgIcon {
  background-color: #006dba;
  width: auto;
  height: auto;
  border-radius: 50%;
  padding: 2.5px;
}

.svgIconDefault {
  // background-color: #006dba;
  width: auto;
  height: auto;
  border-radius: 50%;
  padding: 2.5px;
}

.svgIconPie {
  position: absolute !important;
  left: 50% !important;
  top: 60% !important;
  transform: translate(-50%, -50%) !important; 
}

.TooltipCustom {
  .tooltip-inner {
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 0.3rem !important;
    color: black !important;
    font-size:14px;
    padding:20px !important;
  }
  .arrow::before{
    border-top: 5px solid #143357 !important;
    border-bottom: 1px solid black;
  }
}

.TooltipCustomBlack {
  .tooltip-inner {
    background-color: black !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 0.3rem !important;
    color: white !important;
    font-size:14px;
    padding:10px !important;
  }
  .arrow::before{
    border-top: 5px solid #143357 !important;
    border-bottom: 1px solid black;
  }
}

