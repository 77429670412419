@import "../../../../../config/constants";

.selectInput {
    border: 1px solid $light-blue-border;
    border-radius: 2px;
    padding: 0.4em;
    width: 20em;
    font-size: 16px;
    color: $dark-blue-text;
  }

.selectInputDetail {
    border: 1px solid $light-blue-border;
    border-radius: 2px;
    padding: 0.4em;
    width: 8em;
    font-size: 16px;
    color: $dark-blue-text;
  }

.selectInputModal {
    border: 1px solid $light-blue-border;
    border-radius: 2px;
    padding: 0.4em;
    width: 8em;
    font-size: 16px;
    color: $dark-blue-text;
  }

.fileInput {
    border: 1px dashed $light-blue-border;
    border-radius: 2px;
    padding: 0.4em;
    width: 100%;
    height: 10em;
    font-size: 16px;
    color: $dark-blue-text;
  }

.upload-file-outline {
    color: $light-blue-text !important;
    border: dashed 1px $light-blue-border !important;
    margin: 0px;
    height: 15em;
    cursor: pointer;
    background: $white !important;
}

.btn-outlined {
    color: $green !important;
    background-color: transparent !important;
    border: 1px solid $green !important;
  }


.x-icon {
  position: absolute;
  color: #AF2127;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  width: 40px;
  height: 40px;
  top: 2em;
}